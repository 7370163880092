
.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  color: #66343F;
}

header.App--header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 800px;
  width: 100%;
  flex-grow: 0.2;
}

section {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
}

.App--footer {
  display: flex;
  flex-direction: column;

  flex-grow: 0.2;
  font-size: 1.3em;
  font-weight: 100;
  justify-content: center;
  align-items: center;

  a.email-link {
    display: block;
    // background: url('./mail_dark.png') no-repeat;

    svg {
      width: 50px;
      fill:#66343f;
      fill-opacity: 0.3;
    }

    &:hover {
      svg {
        fill-opacity: 0.7;
      }
    }
  }
}