* {
  padding: 0;
  margin: 0;
}

body, html {
  width: 100%;
  height: 100%;
  display: flex;
}

body, input, button {
  font-family: 'Cairo', sans-serif;
}

#root {
  display: flex;
  flex-grow: 1;
  background-color: #FBDA61;
  background-image: linear-gradient(130deg, #FBDA61 20%, #FF5ACD 100%);
  padding: 1em;
}
