.crp-stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  color: #66343F;
  
  .total {
    font-size: 2.6em;
    font-weight: 400;
    font-family: 'Cairo', sans-serif;
    text-align: right;
  }

  .descr {
    margin-top: 10px;
    font-size: 1.2em;
    opacity: 0.7;
  }
}
