.crp-logo {
  display: flex;
  
  a {
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    color: inherit;
  }

  img {
    width: 50px;
  }

  &--text {
    font-size: 1.6em;
    font-weight: 800;
    padding-left: 13px;
  }
}

