.crp-error {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 18em;
    font-weight: 800;
    margin: 0;
    padding: 0;
    line-height: 1em;
  }

  h2 {
    font-size: 1.7em;
    font-weight: 100;
    margin: 0;
    padding: 0;
    text-align: center;
    opacity: 0.6;

    p {
      padding: 0;
      line-height: 1.3em;
    }
  }
}