/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
*{padding:0;margin:0}body,html{width:100%;height:100%;display:flex}body,input,button{font-family:'Cairo', sans-serif}#root{display:flex;flex-grow:1;background-color:#FBDA61;background-image:linear-gradient(130deg, #FBDA61 20%, #FF5ACD 100%);padding:1em}

/* arabic */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Cairo ExtraLight'), local('Cairo-ExtraLight'), url(/fonts/SLXLc1nY6Hkvalrub46O59Zea3ZlqSo.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* latin-ext */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Cairo ExtraLight'), local('Cairo-ExtraLight'), url(/fonts/SLXLc1nY6Hkvalrub46F59Zea3ZlqSo.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Cairo ExtraLight'), local('Cairo-ExtraLight'), url(/fonts/SLXLc1nY6Hkvalrub46L59Zea3Zl.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Cairo Light'), local('Cairo-Light'), url(/fonts/SLXLc1nY6HkvalqKbI6O59Zea3ZlqSo.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* latin-ext */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Cairo Light'), local('Cairo-Light'), url(/fonts/SLXLc1nY6HkvalqKbI6F59Zea3ZlqSo.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Cairo Light'), local('Cairo-Light'), url(/fonts/SLXLc1nY6HkvalqKbI6L59Zea3Zl.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Cairo Regular'), local('Cairo-Regular'), url(/fonts/SLXGc1nY6HkvalIkTpumxdt0UX8.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* latin-ext */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Cairo Regular'), local('Cairo-Regular'), url(/fonts/SLXGc1nY6HkvalIvTpumxdt0UX8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Cairo Regular'), local('Cairo-Regular'), url(/fonts/SLXGc1nY6HkvalIhTpumxdt0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Cairo SemiBold'), local('Cairo-SemiBold'), url(/fonts/SLXLc1nY6Hkvalr-ao6O59Zea3ZlqSo.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* latin-ext */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Cairo SemiBold'), local('Cairo-SemiBold'), url(/fonts/SLXLc1nY6Hkvalr-ao6F59Zea3ZlqSo.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Cairo SemiBold'), local('Cairo-SemiBold'), url(/fonts/SLXLc1nY6Hkvalr-ao6L59Zea3Zl.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Cairo Bold'), local('Cairo-Bold'), url(/fonts/SLXLc1nY6Hkvalqaa46O59Zea3ZlqSo.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* latin-ext */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Cairo Bold'), local('Cairo-Bold'), url(/fonts/SLXLc1nY6Hkvalqaa46F59Zea3ZlqSo.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Cairo Bold'), local('Cairo-Bold'), url(/fonts/SLXLc1nY6Hkvalqaa46L59Zea3Zl.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Cairo Black'), local('Cairo-Black'), url(/fonts/SLXLc1nY6HkvalqiaY6O59Zea3ZlqSo.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* latin-ext */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Cairo Black'), local('Cairo-Black'), url(/fonts/SLXLc1nY6HkvalqiaY6F59Zea3ZlqSo.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Cairo Black'), local('Cairo-Black'), url(/fonts/SLXLc1nY6HkvalqiaY6L59Zea3Zl.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.App{display:flex;flex-direction:column;width:100%;height:100%;align-items:center;color:#66343F}header.App--header{display:flex;flex-direction:row;justify-content:space-between;max-width:800px;width:100%;flex-grow:0.2}section{display:flex;flex-grow:1;justify-content:center;align-items:center;width:100%;max-width:800px}.App--footer{display:flex;flex-direction:column;flex-grow:0.2;font-size:1.3em;font-weight:100;justify-content:center;align-items:center}.App--footer a.email-link{display:block}.App--footer a.email-link svg{width:50px;fill:#66343f;fill-opacity:0.3}.App--footer a.email-link:hover svg{fill-opacity:0.7}

.crp-logo{display:flex}.crp-logo a{display:flex;text-decoration:none;justify-content:center;align-items:center;color:inherit}.crp-logo img{width:50px}.crp-logo--text{font-size:1.6em;font-weight:800;padding-left:13px}

.crp-stat{display:flex;flex-direction:column;justify-content:center;align-items:flex-end;color:#66343F}.crp-stat .total{font-size:2.6em;font-weight:400;font-family:'Cairo', sans-serif;text-align:right}.crp-stat .descr{margin-top:10px;font-size:1.2em;opacity:0.7}

.crp-error{display:flex;flex-direction:column;align-items:center}.crp-error h1{font-size:18em;font-weight:800;margin:0;padding:0;line-height:1em}.crp-error h2{font-size:1.7em;font-weight:100;margin:0;padding:0;text-align:center;opacity:0.6}.crp-error h2 p{padding:0;line-height:1.3em}

.crp-link-shorter{display:flex;width:100%;line-height:1em;flex-direction:column}.crp-link-shorter form{display:flex;width:100%;flex-direction:row}@media (max-width: 600px){.crp-link-shorter form{flex-direction:column}}.crp-link-shorter .inputGroup{display:flex;flex-direction:row;position:relative;flex-grow:1}.crp-link-shorter .inputGroup .error{position:absolute;top:-20px;left:1em}.crp-link-shorter input,.crp-link-shorter button{line-height:2em;font-size:1.4em;margin:5px}.crp-link-shorter input{flex-grow:1;font-weight:400;padding:2px 13px;border-radius:8px;background:#FEDEDD;color:#651f2e;cursor:text;border:2px solid rgba(0,0,0,0)}.crp-link-shorter input::-webkit-input-placeholder{color:#9e717a}.crp-link-shorter input:-ms-input-placeholder{color:#9e717a}.crp-link-shorter input::-ms-input-placeholder{color:#9e717a}.crp-link-shorter input::placeholder{color:#9e717a}.crp-link-shorter input.link{border:2px solid #D98C9B}.crp-link-shorter input.link:disabled{background:#e8cac9;color:#651f2e}.crp-link-shorter button{background:#5d2a4a;color:#fff;border:none;border-radius:8px;cursor:pointer;position:relative;padding:0 2em}.crp-link-shorter button:hover{background:#4f1f3d}.crp-link-shorter .copyFrame{display:flex;flex-direction:column;align-items:center}.crp-link-shorter .copyFrame .inputGroup{width:100%;margin-bottom:2em}.crp-link-shorter .copyFrame .inputGroup input,.crp-link-shorter .copyFrame .inputGroup input:disabled{color:#651f2e;opacity:1}.crp-link-shorter .copyFrame .inputGroup .click-to-copy{position:absolute;right:1em;height:100%;display:flex;align-items:center;color:#FB7C8D}.crp-link-shorter .copyFrame .inputGroup:hover input{border:2px solid #FA8784;cursor:pointer}.crp-link-shorter .copyFrame .inputGroup:hover .click-to-copy{color:#852264}.crp-link-shorter .copyFrame .new-link{font-size:1.2em;cursor:pointer}.crp-link-shorter .copyFrame .new-link:hover{color:#431822}

.crp-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  margin: 0px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

