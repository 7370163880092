.crp-link-shorter {
  display: flex;
  width: 100%;
  line-height: 1em;
  flex-direction: column;
  
  form {
    display: flex;
    width: 100%;

    flex-direction: row;

    @media (max-width: 600px) {
      flex-direction: column;    
    }
  }

  .inputGroup {
    display: flex;
    flex-direction: row;
    position: relative;
    flex-grow: 1;

    .error {
      position: absolute;
      top: -20px;
      left: 1em;
    }
  }

  input, button {
    line-height: 2em;
    font-size: 1.4em;
    margin: 5px;
  }

  input {
    flex-grow: 1;
    font-weight: 400;
    padding: 2px 13px;
    border-radius: 8px;
    background: #FEDEDD;
    color: #651f2e;
    cursor: text;
    border: 2px solid rgba(0,0,0,0);

    &::placeholder {
      color: #9e717a;
    }
  }

  input.link {
    border: 2px solid #D98C9B;

    &:disabled {
      background: #e8cac9;
      color: #651f2e;
    }

  }

  button {
    background: #5d2a4a;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    padding: 0 2em;

    &:hover {
      background: #4f1f3d;
    }
  }

  .copyFrame {
    display: flex;
    flex-direction: column;
    align-items: center;

    .inputGroup {
      width: 100%;
      margin-bottom: 2em;

      input, input:disabled {
        color: #651f2e;
        opacity: 1;
      }

      .click-to-copy {
        position: absolute;
        right: 1em;
        height: 100%;
        display: flex;
        align-items: center;
        color: #FB7C8D;
      }

      &:hover {
        input {
          border: 2px solid #FA8784;
          cursor: pointer;
        }

        .click-to-copy {
          color: #852264;
        }
      }
    }

    .new-link {
      font-size: 1.2em;
      cursor: pointer;

      &:hover {
        color: #431822;
      }
    }
  }
}